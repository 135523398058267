/* TYPOGRAPHY */
$font-family-main: 'Source Sans Pro', Helvetica, Arial, sans-serif;
$font-family-headings: 'Source Sans Pro', Helvetica, Arial, sans-serif;
$font-family-logo: 'Source Sans Pro', Helvetica, Arial, sans-serif;
$font-size: 1.25em;

$monospace: Consolas, Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New, monospace, sans-serif !default;
$font-size-code: 0.85em !default;
$font-height-code: 1.3em !default;
$border-radius: 4px !default;

/* PADDING */
$padding-x-large: 23%;
$padding-large: 9%;
$padding-medium: 6%;
$padding-small: 4%;
$padding-x-small: 3%;
$title-padding: 1%;
$feature-image-padding: 10%;

/* MOBILE */
$xl-break: 2000px;
$l-break: 1400px;
$m-break: 1000px;
$break: 768px;
$sm-break: 576px;
$x-sm-break: 320px;


/* THEME COLOR SCHEMES */
html, html[data-theme="light"] {
  --background: #ffffff;
  --blockquote: #eef7fa;
  --selection: #d4d4d4;
  --meta: #595959;
  --link: #0072B5;
  --header-background: #24292e;
  --header-text: #ffffff;
  --header-link: #383838;
  --code-background: #E0E0E0;
  --code-inline: #24292e;
  --text-shadow: #3f3f3f;
  --text: #262626;
  --h1: #262626;
  --h2: #262626;
  --h3: #262626;
  --h4: #262626;
  --h5: #262626;
  --h6: #262626;
  --border: rgba(0, 0, 0, 0.2);
}

html[data-theme="dark"] {
  --background: #22272e; // brand, background
  --blockquote: #373e47; // blockquote
  --selection: #545d68; // selection
  --meta: #909dab; // meta
  --link: #00a8e0; // theme, link, link shadow header bg
  --header-background: #2A3444; // Color before header image load
  --header-text: #e7f3f4; // header text, feature img text
  --header-link: #adbac7; // header link, search color
  --code-background: #2A3444;
  --code-inline: #d9b600;
  --text-shadow: #2d333b; // text shadow
  --text: #cdd9e5; // text
  --h1: #d40059; // headers
  --h2: #b37eff;
  --h3: #ff66c2;
  --h4: #00af9e;
  --h5: #6bac00;
  --h6: #ff5c00;
  --border: rgba(255, 255, 255, 0.5); // border
  --cusdis--color-text-default: var(--text);
  --cusdis--color-comment-username-text: var(--link);
}

/* BASE16 CODE HIGHLIGHT */
// Material Theme
html, html[data-theme="light"] {
  --base00: #302f2d; // Default Background
  --base01: #2e3c43; // Lighter Background (Used for status bars) - NOT USED
  --base02: #314549; // Selection Background
  --base03: #546e7a; // Comments, Invisible, Line Highlighting
  --base04: #b2ccd6; // Dark Foreground (Used for status bars)
  --base05: #eeffff; // Punctuation
  --base06: #eeffff; // Highlight, Light Background
  --base07: #ffffff; // Default Foreground, Default text color
  --base08: #f07178; // Operators (and Parenthesis, Caret, Delimiters)
  --base09: #f78c6c; // Classes, Markup Bold, Search Text Background
  --base0a: #ffcb6b; // Strings, Inherited Class, Markup Code, Diff Inserted
  --base0b: #98c379; // Functions, Methods, Attribute IDs, Headings
  --base0c: #89ddff; // Support, Regular Expressions, Escape Characters, Markup Quotes
  --base0d: #82aaff; // Keywords, Storage, Selector, Markup Italic, Diff Changed
  --base0e: #c792ea; // Integers, Boolean, Constants, XML Attributes, Markup Link Url
  --base0f: #ff5370; // Deprecated, Opening/Closing Embedded Language Tags e.g.
}

// Supernova Theme
html[data-theme="dark"] {
  --base00: #373e47; // Default Background
  --base01: #444c56; // Lighter Background (Used for status bars, line number and folding marks)
  --base02: #545d68; // Selection Background
  --base03: #909dab; // Comments, Invisible, Line Highlighting
  --base04: #adbac7; // Dark Foreground (Used for status bars)
  --base05: #cdd9e5; // Default Foreground, Caret, Delimiters, Operators
  --base06: #e7f3f4; // Light Foreground (Not often used)
  --base07: #ffffff; // Light Background (Not often used)
  --base08: #d40059; // Variables, XML Tags, Markup Link Text, Markup Lists, Diff Deleted
  --base09: #ff5c00; // Integers, Boolean, Constants, XML Attributes, Markup Link Url
  --base0a: #d9b600; // Classes, Markup Bold, Search Text Background
  --base0b: #6bac00; // Strings, Inherited Class, Markup Code, Diff Inserted
  --base0c: #00a8e0; // Support, Regular Expressions, Escape Characters, Markup Quotes
  --base0d: #b37eff; // Functions, Methods, Attribute IDs, Headings
  --base0e: #00af9e; // Keywords, Storage, Selector, Markup Italic, Diff Changed
  --base0f: #ff66c2; // Deprecated, Opening/Closing Embedded Language Tags, e.g. <?php ?>
}

// Fullcalendar light mode
html, [data-theme="light"] {
  --fc-page-bg-color: var(--background);
  --fc-event-bg-color: var(--background);
  --fc-event-text-color: var(--link);
  --fc-event-border-color: var(--link);
  --fc-button-bg-color: var(--background);
  --fc-button-border-color: var(--link);
  --fc-button-text-color: var(--link);
  --fc-button-hover-bg-color: var(--meta);
}

// Fullcalendar dark mode
html, [data-theme="dark"] {
  --fc-page-bg-color: var(--background);
  --fc-event-bg-color: var(--link);
  --fc-event-border-color: var(--link);
  --fc-event-border-color: var(--link);
  --fc-today-bg-color: rgba(181, 125, 255, 0.15);
  --fc-neutral-text-color: var(--fc-border-color);
  --fc-button-bg-color: var(--background);
  --fc-button-border-color: var(--link);
  --fc-button-text-color: var(--link);
  --fc-button-hover-bg-color: var(--meta);
}

// Monokai Theme
//html[data-theme="dark"] {
//  --base00: #272822; // Default Background
//  --base01: #383830; // Lighter Background (Used for status bars)
//  --base02: #49483e; // Selection Background
//  --base03: #75715e; // Comments, Invisible, Line Highlighting
//  --base04: #a59f85; // Dark Foreground (Used for status bars)
//  --base05: #f8f8f2; // Light Foreground (Not often used)
//  --base06: #f5f4f1; // Light Background (Not often used)
//  --base07: #f9f8f5; // Default text color
//  --base08: #f92672; // Parenthesis, Caret, Delimiters, Operators
//  --base09: #fd971f; // Classes, Markup Bold, Search Text Background
//  --base0a: #e6db74; // Strings, Inherited Class, Markup Code, Diff Inserted
//  --base0b: #a6e22e; // Functions, Methods, Attribute IDs, Headings
//  --base0c: #a1efe4; // Support, Regular Expressions, Escape Characters, Markup Quotes
//  --base0d: #66d9ef; // Keywords, Storage, Selector, Markup Italic, Diff Changed
//  --base0e: #ae81ff; // Integers, Boolean, Constants, XML Attributes, Markup Link Url
//  --base0f: #cc6633; // Deprecated, Opening/Closing Embedded Language Tags e.g.
//}
