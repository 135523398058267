.site-footer {
  @extend %padding-post;
  z-index: -1;
  display: inline-block;
  text-align: center;
  width: 100%;
  color: var(--meta);
  font-size: 0.9em;

  .footer-icons {
    margin-bottom: 1em;
    
    ul {
      list-style: none;
      margin: 0;

      li {
        display: inline;
      }

      a {
        color: var(--link);
      }

      a:hover {
        .fa-circle {
          filter: brightness(0.85);
        }
      }
    }
  }

  .footer-links {
    
    ul {
      list-style: none;
      margin: 0;

      li {
        display: inline-block;
        margin: 0 0.5em;

        a {
          color: var(--meta);
        }
  
        a:hover {
          color: var(--link);
        }

      }
    }
  }
}
